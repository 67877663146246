import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";

import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`; // get current path

    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function(event) {
        const parentLiEle = that.closest(this, "li");
        if (menuLi[i].classList.contains("menu") && parentLiEle !== null) {
          event.stopPropagation();

          if (menuLi[i].classList.contains("open")) {
            menuLi[i].classList.remove("open", "active");
          } else {
            menuLi[i].classList.add("open", "active");
          }
        } else {
          for (let j = 0; j < menuLi.length; j++) {
            const parentLi = that.closest(this, "li");
            if (
              menuLi[j] !== this &&
              (parentLi === null || !parentLi.classList.contains("open"))
            ) {
              menuLi[j].classList.remove("open");
            } else {
              if (menuLi[j].classList.contains("open")) {
                menuLi[j].classList.remove("open");
              } else {
                menuLi[j].classList.add("open");
              }
            }
          }
        }
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector"
      ].some(function(fn) {
        if (typeof document.body[fn] === "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }

  openLink = (url, name, evt) => {

    if(window.ReactNativeWebView !== undefined && window.ReactNativeWebView.postMessage !== undefined){
      this.sendMsgToRN("vai receber um link...");
      let param = "{\"link\": \""+url+"\" }"; 
      console.log("window.ReactNativeWebView.postMessage sendMsgDownloadToRN", param);
      window.ReactNativeWebView.postMessage(param);
      this.sendMsgToRN("terminou o envio do link.");
    } else {
      console.log("nao tem RN/WebView... é browser.");
      this.openLinkNatural(url);
    }

    const corpoApp = document.getElementsByClassName("MuiBackdrop-root")[0];
    if( corpoApp !== undefined ){
      corpoApp.click();
    }
    
  };

  sendMsgToRN = (msg) => {
    let param = "{\"text\": \"" + msg + "\"}" ;
    console.log("window.ReactNativeWebView.postMessage sendMsgToRN", '\'' + JSON.stringify(param) + '\'');
    window.ReactNativeWebView.postMessage(param);
  };

  openLinkNatural = (url, name) => {

    var link = document.createElement("a");
    link.download = name;
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  };

  render() {

    let userId = localStorage.getItem("user_id");
    let userSettings = '';
    if(userId){
      userSettings = JSON.parse(
        localStorage.getItem(
          "userSettings_" + userId
        )
      );
    }

     
    const googleDrivePathBackupId = userSettings.client._source.googleDrivePathBackupId;
    //console.log('|||| googleDrivePathBackupId',googleDrivePathBackupId);
    const profileTypeId = userSettings.profile._source.profileTypeId;

    const accessdrive = userSettings.profile._source.accessdrive ? userSettings.profile._source.accessdrive : false;

    //console.log('|||| profileTypeId',profileTypeId);    
    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
          <li className="nav-header">
            <IntlMessages id="appModule.sidebar.principal" />
          </li>

          {/* <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="appModule.sidebar.dashboard" />
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/dashboard/eCommerce">
                  <span className="nav-text text-transform-none">
                    <IntlMessages id="appModule.sidebar.dashboard.stats" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li> */}

          <li className="ui_tooltip menu">
            <Button className="void">
              <i className="zmdi zmdi-collection-folder-image zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="appModule.sidebar.timelapses" />
              </span>
            </Button>

            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/timelapse/explorer">
                  <span className="nav-text">
                    <IntlMessages id="appModule.sidebar.images.explorer" />
                  </span>
                </NavLink>
              </li>
              {/* <li>
                <NavLink className="prepend-icon" to="/app/timelapse/compare">
                  <span className="nav-text">
                    <IntlMessages id="appModule.sidebar.images.compare" />
                  </span>
                </NavLink>
              </li> */}
              <li>
                <NavLink className="prepend-icon" to="/app/timelapse/compare" >
                  <span className="nav-text">
                    <IntlMessages id="appModule.sidebar.images.overlays" />
                  </span>
                </NavLink>
              </li>
              {/* <li>
                <NavLink className="prepend-icon" to="/app/components/avatars">
                  <span className="nav-text"><IntlMessages id="sidebar.components.avatars"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/components/badges">
                  <span className="nav-text"><IntlMessages id="sidebar.components.badge"/></span>
                </NavLink>
              </li> */}
            </ul>
          </li>

          <li className="menu collapse-box">
            <Button>
              {/* zmdi-tv-list | zmdi-comment-list | zmdi-assignment | zmdi-shape |  */}
              <i className="zmdi zmdi-widgets zmdi-hc-fw" /> 
              <span className="nav-text">
                <IntlMessages id="appModule.sidebar.images" />
              </span>
            </Button>
            <ul className="sub-menu">
              {/* <li>
                <a class="nav-text" href={"https://drive.google.com/drive/folders/1lAvR7c2ur6Kg2eiZ2Az8X-GJyHTDwAaz"} target="_blank">
                  <span className="nav-text">
                    <IntlMessages id="appModule.sidebar.timelapses.videos" />
                  </span>
                </a>
              </li> */}
              <li>
                <NavLink className="prepend-icon" to="/app/timelapse/reports/videos">
                  <span className="nav-text">
                    <IntlMessages id="appModule.sidebar.timelapses.videos" />
                  </span>
                </NavLink>
              </li>
              {/* <li>
                <a class="nav-text" href={"https://drive.google.com/drive/folders/1BD90WTzVu8rZ6OCu2gV1gjRVVD7l8DWs"} target="_blank">
                  <span className="nav-text">
                    <IntlMessages id="appModule.sidebar.timelapses.images" />
                  </span>
                </a>
              </li> */}
              <li>
                <NavLink to="/app/timelapse/reports/images">
                  <span className="nav-text">
                    <IntlMessages id="appModule.sidebar.timelapses.images" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/timelapse/reports/virtualtours">
                  <span className="nav-text">
                    <IntlMessages id="appModule.sidebar.timelapses.virtualtours" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/report/request">
                  <span className="nav-text">
                    <IntlMessages id="appModule.sidebar.timelapses.request" />
                  </span>
                </NavLink>
              </li>
              {/* {accessdrive && accessdrive == true &&
              <li>
                <a href={"https://drive.google.com/drive/folders/"+googleDrivePathBackupId} target="_blank">
                  <span className="nav-text">
                    <IntlMessages id="appModule.sidebar.timelapses.backups" />
                  </span>
                </a>
              </li>
              } */}
                              
            </ul>
          </li>

          {profileTypeId <= 3 &&
            <li className="nav-header">
              <IntlMessages id="appModule.sidebar.admin" />
            </li>
          }
          {profileTypeId <= 2 &&
            <li className="menu no-arrow">
              <NavLink to="/app/admin/clients">
                <i className="zmdi zmdi-city zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="appModule.sidebar.admin.client" />
                </span>
              </NavLink>
            </li>
          }
          {profileTypeId <= 2 &&
            <li className="menu no-arrow">
              <NavLink to="/app/admin/locals">
                <i className="zmdi zmdi-pin-drop zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="appModule.sidebar.admin.locals" />
                </span>
              </NavLink>
            </li>
          }
          {profileTypeId <= 2 &&
            <li className="menu no-arrow">
              <NavLink to="/app/admin/cameras">
                <i className="zmdi zmdi-camera zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="appModule.sidebar.admin.cameras" />
                </span>
              </NavLink>
            </li>
          }
          {profileTypeId <= 2 &&
            <li className="menu no-arrow">
              <NavLink to="/app/admin/virtualtours">
                <i className="zmdi zmdi-globe-alt zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="appModule.sidebar.admin.virtualtours" />
                </span>
              </NavLink>
            </li>
          }
          {profileTypeId <= 3 &&
            <li className="menu no-arrow">
              <NavLink to="/app/admin/profiles">
                <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="appModule.sidebar.admin.profiles" />
                </span>
              </NavLink>
            </li>
          }
          {profileTypeId <= 3 &&
            <li className="menu no-arrow">
              <NavLink to="/app/admin/users">
                <i className="zmdi zmdi-account-box zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="appModule.sidebar.admin.users" />
                </span>
              </NavLink>
            </li>
          }
          {profileTypeId <= 3 &&
            <li className="menu no-arrow">
              <NavLink to="/app/admin/permissions">
                <i className="zmdi zmdi-shield-security zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="appModule.sidebar.admin.permissions" />
                </span>
              </NavLink>
            </li>
          }
          {profileTypeId <= 2 &&
            <li className="menu no-arrow">
              <NavLink to="/app/admin/requests">
                <i className="zmdi zmdi-comment zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="appModule.sidebar.admin.requests" />
                </span>
              </NavLink>
            </li>
          }

          {profileTypeId <= 3 &&
            <li className="nav-header">
              <IntlMessages id="appModule.sidebar.extra" />
            </li>
          }
          {(profileTypeId <= 2 || accessdrive === true) && 
              <li className="menu no-arrow">
                <a class="nav-text" onClick={(evt) => {
                      this.openLink("https://drive.google.com/drive/folders/"+googleDrivePathBackupId, null, evt);
                  }}>
                  <i className="zmdi zmdi-google-drive zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="appModule.sidebar.timelapses.drive" />
                  </span>
                </a>
              </li>

          }
          



        </ul>
      </CustomScrollbars>
    );
  }
}

export default withRouter(SidenavContent);
