import React from "react";
import { connect } from "react-redux";
import { userSignOut } from "actions/Auth";
import IntlMessages from "util/IntlMessages";
import { NavLink, Link } from "react-router-dom";

class UserInfoPopup extends React.Component {
  render() {
    const imgAvatar = JSON.parse(
      localStorage.getItem("userSettings_" + localStorage.getItem("user_id"))
    ).user._source.avatar;
    const userName = JSON.parse(
      localStorage.getItem("userSettings_" + localStorage.getItem("user_id"))
    ).user._source.name;
    const clientName = JSON.parse(
      localStorage.getItem("userSettings_" + localStorage.getItem("user_id"))
    ).client._source.name;
    //console.log('|||| imgAvatar',imgAvatar);

    return (
      <div id="app-userInfoPopup">
        <div className="user-profile">
          <img
            className="user-avatar border-0 size-40 rounded-circle mr-2"
            src={
              imgAvatar !== undefined
                ? imgAvatar
                : require("assets/images/favicon-32x32.png")
            }
            //src={require("assets/images/logo-mpd.png")}
            alt="User"
          />
          <div className="user-detail">
            <h4 className="user-name mb-0">
              {userName !== undefined ? userName : "User"}{" "}
            </h4>
            <small>{clientName !== undefined ? clientName : "Client"}</small>
          </div>
        </div>
        {/* <span className="jr-link dropdown-item text-muted">
          <i className="zmdi zmdi-face zmdi-hc-fw mr-1"/>
          <IntlMessages id="popup.profile"/>
        </span>
        <span className="jr-link dropdown-item text-muted">
          <i className="zmdi zmdi-settings zmdi-hc-fw mr-1"/>
          <IntlMessages id="popup.setting"/>
        </span> */}
        <span className="jr-link dropdown-item text-muted">
          <Link
            to="/app/pass/change"
            style={{ color: "rgb(108 117 125)", textDecoration: "none" }}
            // onClick={
            //   document.getElementById("app-userInfoPopup")
            //     ? document.getElementById("app-userInfoPopup").click()
            //     : undefined
            // }
          >
            <i className="zmdi zmdi-refresh-alt zmdi-hc-fw mr-1" />
            {/* <span className="nav-text"> */}
            <IntlMessages id="appModule.infoUser.change.pass" />
            {/* </span> */}
          </Link>
        </span>

        <span
          className="jr-link dropdown-item text-muted"
          onClick={() => {
            console.log("Try to logoput");
            this.props.userSignOut();
          }}
        >
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1" />
          <IntlMessages id="popup.logout" />
        </span>
      </div>
    );
  }
}

export default connect(null, { userSignOut })(UserInfoPopup);
