//import React, { Component } from "react";
// import {
//     HIDE_MESSAGE,
//     INIT_URL,
//     ON_HIDE_LOADER,
//     ON_SHOW_LOADER,
//     SHOW_MESSAGE,
//     SIGNIN_USER_SUCCESS,
//     SIGNOUT_USER_SUCCESS,
//     SIGNUP_USER_SUCCESS
//   } from "constants/ActionTypes";
  import axios from "util/Api";

export class Auth {}

export const signInWithEmailAndPassword = async function(email, password) {
  console.log("internal signInWithEmailAndPassword", email, password);
  return await new Promise(resolve => {
    console.log('signInWithEmailAndPassword');
    axios
      .post("authenticate", {
        email: email,
        password: password
      })
      .then(({ data }) => {
        console.log("userSignIn: ", data);
        if (data.token) {
          //localStorage.setItem("token", data.token);
          //localStorage.setItem("user_id", data.user._id);

          axios.defaults.headers.common["Authorization"] =
            "Bearer " + data.token;
        }
        resolve(data);
      })
      .catch(function(error) {
        console.log("Error****:", error.message);

        resolve(error);
      });
  });
};

export const getUserFromToken = async function() {
  console.log(
    "internal getUserFromToken",
    axios.defaults.headers.common["Authorization"]
  );
  return await new Promise(resolve => {
    //console.log("getUserFromToken");
    axios
      .get("me")
      .then(({ data }) => {
        console.log("Decoded User: ", data);
        resolve(data);
      })
      .catch(function(error) {
        console.log("Error****:", error.message);

        resolve(error);
      });
  });
};

// export const signInWithEmailAndPassword = async function (email, password) {
//     console.log('internal signInWithEmailAndPassword', email, password);
//         return await new Promise((resolve) => {
//             setTimeout(() => {
//              resolve({
//                 'user' : {
//                     'uid': 5644867
//                 },
//                 'authUser': {
//                     'email': email,
//                     'password': password
//                 }
//               });
//              }, 500);
//             });
//   };

// export const createUserWithEmailPasswordRequest = (email, password) => {
//     // TODO: implementar o metodo
//     return {
//         'email': email,
//         'password': password
//     }
// };

 

// export const signOutRequest = () => {
//     // TODO: implementar o metodo
//     return true;
// };


export default {Auth};


