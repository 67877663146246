import brLang from './entries/pt-BR';
import enLang from './entries/en-US';
import esLang from './entries/es_ES';
import {addLocaleData} from 'react-intl';

const AppLocale = {
  br: brLang,
  en: enLang,
  es: esLang
};
addLocaleData(AppLocale.br.data);
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.es.data);

export default AppLocale;
