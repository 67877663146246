import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
} from "../constants/ActionTypes";
import axios from "util/Api";
import { signInWithEmailAndPassword, getUserFromToken } from "services/Auth";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const userSignUp = ({ name, email, password }) => {
  console.log(name, email, password);
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("auth/register", {
        email: email,
        password: password,
        name: name,
      })
      .then(({ data }) => {
        console.log("data:", data);
        if (data.result) {
          localStorage.setItem(
            "token",
            JSON.stringify(data.token.access_token)
          );
          axios.defaults.headers.common["access-token"] =
            "Bearer " + data.token.access_token;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_TOKEN_SET, payload: data.token.access_token });
          dispatch({ type: USER_DATA, payload: data.user });
        } else {
          console.log("payload: data.error", data.error);
          dispatch({ type: FETCH_ERROR, payload: "Network Error" });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const userSignIn = ({ email, password }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    console.log("Executando userSignIn");
    console.log("email", email);
    //console.log('password',password);
    // local
    // let tokenTemp = {
    //   'user_id': '5645345',
    //   'access_token': 'ABCDEFGHIJKLMNOPKRSTUWYVXZ',
    //   'token_type': 'bearer',
    //   'expires_in': 60 * 10
    // }
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    //localStorage.setItem("token", JSON.stringify(tokenTemp.access_token));
    //localStorage.setItem("user_id", JSON.stringify(tokenTemp.user_id));
    // setTimeout(() => {
    //   dispatch({type: FETCH_SUCCESS});
    //   dispatch({type: USER_TOKEN_SET, payload: tokenTemp.access_token});
    // }, 1000);

    signInWithEmailAndPassword(email, password).then((resultSignIn) => {
      console.log("signInWithEmailAndPassword", resultSignIn);

      if (resultSignIn.token) {
        localStorage.setItem("token", resultSignIn.token);
        localStorage.setItem("user_id", resultSignIn.user._id);
        // localStorage.setItem("token", JSON.stringify(resultSignIn.token));
        // localStorage.setItem("user_id", JSON.stringify(resultSignIn.user_id));
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: USER_TOKEN_SET, payload: resultSignIn.token });
      } else {
        console.log("Erro resultSignIn", resultSignIn);
        if (
          resultSignIn.response !== undefined &&
          resultSignIn.response.data !== undefined &&
          resultSignIn.response.data.error !== undefined
        ) {
          dispatch({
            type: FETCH_ERROR,
            payload: resultSignIn.response.data.error,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: resultSignIn + "",
          });
        }
      }
    });
  };
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    // local
    // let userTemp = {
    //   'name': 'Teste',
    //   'email': 'lavandosk@gmail.com',
    //   'password': '1234'
    // };
    // dispatch({type: FETCH_SUCCESS});
    // dispatch({type: USER_DATA, payload: userTemp});

    getUserFromToken().then((resultUserFromToken) => {
      console.log("resultUserFromToken", resultUserFromToken);
      if (
        resultUserFromToken &&
        resultUserFromToken.decodedToken &&
        resultUserFromToken.decodedToken.id
      ) {
        // let user = {
        //   name: resultUserFromToken.name,
        //   email: resultUserFromToken.email,
        //   password: ''
        // };
        // let user = {
        //   name: "Teste",
        //   email: "lavandosk@gmail.com",
        //   password: "1234"
        // };
        localStorage.setItem(
          "user_" + localStorage.getItem("user_id"),
          JSON.stringify(resultUserFromToken.decodedToken)
        );
        localStorage.setItem(
          "userSettings_" + localStorage.getItem("user_id"),
          JSON.stringify(resultUserFromToken)
        );
        console.log("getUserFromToken", resultUserFromToken.decodedToken);
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: USER_DATA,
          payload: resultUserFromToken.decodedToken,
        });
      } else {
        dispatch({ type: FETCH_START });

        // local
        dispatch({ type: FETCH_SUCCESS });
        localStorage.removeItem("token");
        //localStorage.removeItem("user_id");

        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: SIGNOUT_USER_SUCCESS });
      }
    });
  };
};

export const userSignOut = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    // local
    dispatch({ type: FETCH_SUCCESS });
    localStorage.removeItem("token");
    //localStorage.removeItem("user_id");

    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: SIGNOUT_USER_SUCCESS });

    // axios.post('auth/logout',
    // ).then(({data}) => {
    //   if (data.result) {
    //     dispatch({type: FETCH_SUCCESS});
    //     localStorage.removeItem("token");
    //     dispatch({type: FETCH_SUCCESS});
    //     dispatch({type: SIGNOUT_USER_SUCCESS});
    //   } else {
    //     dispatch({type: FETCH_ERROR, payload: data.error});
    //   }
    // }).catch(function (error) {
    //   dispatch({type: FETCH_ERROR, payload: error.message});
    //   console.log("Error****:", error.message);
    // });
  };
};
