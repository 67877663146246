import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "components/Header/index";
import Sidebar from "containers/SideNav/index";
import Footer from "components/Footer";
import Dashboard from "./routes/dashboard";
import Widgets from "./routes/widgets";
import Metrics from "./routes/metrics";
import Components from "./routes/components";
import Icons from "./routes/icons/index";
import Form from "./routes/form";
import Editors from "./routes/editors";
import Pickers from "./routes/pickers";
import Extensions from "./routes/extensions";
import Table from "./routes/table";

import DataTable from "./routes/datatable";
import Clients from "./routes/admin-clients/grid";

import Admin from "./routes/admin";
import Timelapse from "./routes/timelapse";
import Report from "./routes/report";
import ChangePass from "./routes/changepass";

import Chart from "./routes/charts";
import Map from "./routes/map";
import Calendar from "./routes/calendar";
import TimeLine from "./routes/timeLine";
import CustomViews from "./routes/customViews";
import ExtraElements from "./routes/extraElements";
import eCommerce from "./routes/eCommerce";
import AppModule from "./routes/appModule";
import ExtraPages from "./routes/extraPages";
import Tour from "../components/Tour/index";
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "constants/ActionTypes";
// import ColorOption from 'containers/Customizer/ColorOption';
import { isIOS, isMobile } from "react-device-detect";
import asyncComponent from "../util/asyncComponent";
import TopNav from "components/TopNav";
import SocialApps from "./routes/socialApps";

class App extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     openEditPass: false,
  //   };
  // }

  // openChangePass = () => {
  //   console.log("App.openChangePass");
  //   this.setState({ openEditPass: true });
  // };

  // closeChangePass = () => {
  //   this.setState({ openEditPass: false });
  // };

  render() {
    const {
      match,
      drawerType,
      navigationStyle,
      horizontalNavPosition,
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "fixed-drawer"
      : drawerType.includes(COLLAPSED_DRAWER)
      ? "collapsible-drawer"
      : "mini-drawer";

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        <Tour />

        <Sidebar
        // openChangePass={this.openChangePass}
        />
        <div id="app-main-container" className="app-main-container">
          {/* <ChangePass
            openEditPass={this.state.openEditPass}
            onPassClose={this.closeChangePass}
          /> */}

          <div
            className={`app-header ${
              navigationStyle === HORIZONTAL_NAVIGATION
                ? "app-header-horizontal"
                : ""
            }`}
          >
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === ABOVE_THE_HEADER && (
                <TopNav styleName="app-top-header" />
              )}
            <Header />
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                <Route path={`${match.url}/dashboard`} component={Dashboard} />
                <Route
                  path={`${match.url}/social-apps`}
                  component={SocialApps}
                />
                <Route
                  path={`${match.url}/components`}
                  component={Components}
                />
                <Route path={`${match.url}/icons`} component={Icons} />
                <Route path={`${match.url}/form`} component={Form} />
                <Route path={`${match.url}/editor`} component={Editors} />
                <Route path={`${match.url}/pickers`} component={Pickers} />
                <Route
                  path={`${match.url}/extensions`}
                  component={Extensions}
                />
                <Route path={`${match.url}/table`} component={Table} />
                <Route path={`${match.url}/datatable`} component={DataTable} />

                {/* <Route path={`${match.url}/admin/clients`} component={Clients}/> */}
                <Route path={`${match.url}/admin`} component={Admin} />
                <Route path={`${match.url}/timelapse`} component={Timelapse} />
                <Route path={`${match.url}/report`} component={Report} />
                <Route path={`${match.url}/pass`} component={ChangePass} />

                <Route path={`${match.url}/chart`} component={Chart} />
                <Route path={`${match.url}/map`} component={Map} />
                <Route path={`${match.url}/calendar`} component={Calendar} />
                <Route path={`${match.url}/time-line`} component={TimeLine} />
                <Route
                  path={`${match.url}/custom-views`}
                  component={CustomViews}
                />

                <Route path={`${match.url}/widgets`} component={Widgets} />
                <Route path={`${match.url}/metrics`} component={Metrics} />
                <Route
                  path={`${match.url}/extra-elements`}
                  component={ExtraElements}
                />
                <Route path={`${match.url}/ecommerce`} component={eCommerce} />
                <Route path={`${match.url}/app-module`} component={AppModule} />
                <Route
                  path={`${match.url}/extra-pages`}
                  component={ExtraPages}
                />
                <Route
                  component={asyncComponent(() =>
                    import("app/routes/extraPages/routes/404")
                  )}
                />
              </Switch>
            </div>
            <Footer />
          </main>
        </div>
        {/* <ColorOption/> */}
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, navigationStyle, horizontalNavPosition };
};
export default withRouter(connect(mapStateToProps)(App));
