import axios from "axios";

export default axios.create({
  baseURL: `https://rtimelapse.com:8443/api/`, //YOUR_API_URL HERE SERVER DIGITAL OCEAN
  //baseURL: `http://67.205.182.173:8333/api/`,
  //baseURL: `http://192.168.43.26:3333/api/`,//YOUR_API_URL HERE LOCAL CELULAR LUCIANO
  // baseURL: `http://192.168.15.20:3333/api/`,//YOUR_API_URL HERE LOCAL WIFI LUCIANO
  //baseURL: `http://192.168.15.22:8333/api/`,//YOUR_API_URL HERE LOCAL WIFI LUCIANO
  //baseURL: `http://localhost:8333/api/`, //YOUR_API_URL HERE LOCAL
  //baseURL: `http://192.168.1.100:3333/api/`, //YOUR_API_URL HERE LOCAL

  headers: {
    "Content-Type": "application/json",
  },
});
