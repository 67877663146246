import React, {Component} from 'react';
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import URLSearchParams from 'url-search-params'
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl';
import "assets/vendors/style";

import indigoTheme from './themes/indigoTheme';
import cyanTheme from './themes/cyanTheme';
import orangeTheme from './themes/orangeTheme';
import amberTheme from './themes/amberTheme';
import pinkTheme from './themes/pinkTheme';
import blueTheme from './themes/blueTheme';
import purpleTheme from './themes/purpleTheme';
import greenTheme from './themes/greenTheme';

import greyTheme from './themes/greyTheme';
import blueGreyTheme from './themes/blueGreyTheme';
import redTheme from './themes/redTheme';
import tealTheme from './themes/tealTheme';

import darkTheme from './themes/darkTheme';
import AppLocale from '../lngProvider';
import {
  AMBER,
  BLUE,
  CYAN,
  DARK_AMBER,
  DARK_BLUE,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK,
  DARK_RED,
  DARK_GREY,
  DARK_TEAL,
  DARK_BLUE_GREY,
  RED,
  GREY,
  TEAL,
  BLUE_GREY
} from 'constants/ThemeColors';

import MainApp from 'app/index';
import SignIn from './SignIn';
import SignUp from './SignUp';
import {getUser, setInitUrl} from '../actions/Auth';
import RTL from 'util/RTL';
import axios from 'util/Api';
import asyncComponent from 'util/asyncComponent';
import {setDarkTheme, setThemeColor} from "../actions/Setting";

const RestrictedRoute = ({component: Component, token, ...rest}) =>
  <Route
    {...rest}
    render={props => 
      token
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: localStorage.getItem("userInitURL_" + localStorage.getItem("user_id")) !== undefined ? localStorage.getItem("userInitURL_" + localStorage.getItem("user_id")): '/signin',
            state: {from: props.location}
          }}
        />}
  />;

class App extends Component {

  componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    const params = new URLSearchParams(this.props.location.search);
    if (params.has("theme-name")) {
      this.props.setThemeColor(params.get('theme-name'));
    }
    if (params.has("dark-theme")) {
      this.props.setDarkTheme();
    }
    console.log('props',this.props);
    //localStorage.setItem("timelapseProps", JSON.stringify(this.props));
  }

  getColorTheme(themeColor, applyTheme) {
    switch (themeColor) {
      case INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      case CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }

      case PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }

      case RED: {
        applyTheme = createMuiTheme(redTheme);
        break;
      }
      case TEAL: {
        applyTheme = createMuiTheme(tealTheme);
        break;
      }
      case GREY: {
        applyTheme = createMuiTheme(greyTheme);
        break;
      }
      case BLUE_GREY: {
        applyTheme = createMuiTheme(blueGreyTheme);
        break;
      }


      case DARK_INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      case DARK_CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case DARK_AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DARK_DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }

      case DARK_PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case DARK_BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DARK_DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case DARK_GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }

      case DARK_RED: {
        applyTheme = createMuiTheme(redTheme);
        break;
      }
      case DARK_GREY: {
        applyTheme = createMuiTheme(greyTheme);
        break;
      }
      case DARK_TEAL: {
        applyTheme = createMuiTheme(tealTheme);
        break;
      }
      case DARK_BLUE_GREY: {
        applyTheme = createMuiTheme(blueGreyTheme);
        break;
      }

      default : createMuiTheme(blueGreyTheme);

    }
    return applyTheme;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.token) {
      axios.defaults.headers.common['Authorization'] = "Bearer " + nextProps.token;
    }
    if (nextProps.token && !nextProps.authUser) {
      this.props.getUser()

    }
  }

  render() {
    
    const {match, location, isDarkTheme, locale, token, initURL, isDirectionRTL} = this.props;
    let {themeColor} = this.props;
    let applyTheme = createMuiTheme(blueGreyTheme);
    if (isDarkTheme) {
      document.body.classList.add('dark-theme');
      applyTheme = createMuiTheme(darkTheme)
    } else {
      applyTheme = this.getColorTheme(themeColor, applyTheme);
    }

    const userInitURL = localStorage.getItem(
      "userInitURL_" + localStorage.getItem("user_id")
    );
    console.log('App location.pathname',location.pathname);
    console.log('App token',token);
    console.log('App userInitURL',userInitURL);

    if (location.pathname === '/') {
      if (token === null) {

        if(location.pathname === '/omprodutora'){
          return ( <Redirect to={'/omprodutora'}/> );
        }else if(location.pathname === '/login'){
          return ( <Redirect to={'/login'}/> );
        }else{
          return ( <Redirect to={'/signin'}/> );
        }
        
      } else if (initURL === '' || initURL === '/' 
        || initURL === '/signin'
        || initURL === '/login'
        || initURL === '/omprodutora'
        ) {
        localStorage.setItem(
          "userInitURL_" + localStorage.getItem("user_id"),
          initURL);
        
        return ( <Redirect to={'/app/timelapse/explorer'}/> );
      } else {
          return ( <Redirect to={initURL}/> );
      }
    }
    if (isDirectionRTL) {
      applyTheme.direction = 'rtl';
      document.body.classList.add('rtl')
    } else {
      document.body.classList.remove('rtl');
      applyTheme.direction = 'ltr';
    }

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <ThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <RTL>
              <div className="app-main">
                <Switch>
                  <RestrictedRoute path={`${match.url}app`} token={token}
                                   component={MainApp}/>
                  <Route path='/signin' component={SignIn}/>
                  <Route path='/login' component={SignIn}/>
                  <Route path='/omprodutora' component={SignIn}/>
                  <Route path='/signup' component={SignUp}/>
                  <Route
                    component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
                </Switch>
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = ({settings, auth}) => {
  const {themeColor, sideNavColor, darkTheme, locale, isDirectionRTL} = settings;
  const {authUser, token, initURL} = auth;
  return {themeColor, sideNavColor, isDarkTheme: darkTheme, locale, isDirectionRTL, token, authUser, initURL}
};

export default connect(mapStateToProps, {setInitUrl, setThemeColor, setDarkTheme, getUser})(App);