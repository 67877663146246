import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";

import IntlMessages from "util/IntlMessages";

class Menu extends Component {
  componentDidMount() {
    const { history } = this.props;

    const pathname = `#${history.location.pathname}`; // get current path
    const mainMenu = document.getElementsByClassName("nav-item");
    for (let i = 0; i < mainMenu.length; i++) {
      mainMenu[i].onclick = function() {
        for (let j = 0; j < mainMenu.length; j++) {
          if (mainMenu[j].classList.contains("active")) {
            mainMenu[j].classList.remove("active");
          }
        }
        this.classList.toggle("active");
      };
    }
    const subMenuLi = document.getElementsByClassName("nav-arrow");
    for (let i = 0; i < subMenuLi.length; i++) {
      subMenuLi[i].onclick = function() {
        for (let j = 0; j < subMenuLi.length; j++) {
          if (subMenuLi[j].classList.contains("active")) {
            subMenuLi[j].classList.remove("active");
          }
        }
        this.classList.toggle("active");
      };
    }
    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("active");
      } else {
        this.closest(activeLi, "li").classList.add("active");
      }
      const parentNav = this.closest(activeNav, ".nav-item");
      if (parentNav) {
        parentNav.classList.add("active");
      }
    } catch (e) {}
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector"
      ].some(function(fn) {
        if (typeof document.body[fn] === "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }

  openLink = (url, name, evt) => {

    if(window.ReactNativeWebView !== undefined && window.ReactNativeWebView.postMessage !== undefined){
      this.sendMsgToRN("vai receber um link...");
      let param = "{\"link\": \""+url+"\" }"; 
      console.log("window.ReactNativeWebView.postMessage sendMsgDownloadToRN", param);
      window.ReactNativeWebView.postMessage(param);
      this.sendMsgToRN("terminou o envio do link.");
    } else {
      console.log("nao tem RN/WebView... é browser.");
      this.openLinkNatural(url);
    }

    const corpoApp = document.getElementsByClassName("MuiBackdrop-root")[0];
    if( corpoApp !== undefined ){
      corpoApp.click();
    }
    
  };

  sendMsgToRN = (msg) => {
    let param = "{\"text\": \"" + msg + "\"}" ;
    console.log("window.ReactNativeWebView.postMessage sendMsgToRN", '\'' + JSON.stringify(param) + '\'');
    window.ReactNativeWebView.postMessage(param);
  };

  openLinkNatural = (url, name) => {

    var link = document.createElement("a");
    link.download = name;
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  };
  
  render() {
    const userSettings = JSON.parse(
      localStorage.getItem(
        "userSettings_" + localStorage.getItem("user_id")
      )
    );

    const googleDrivePathBackupId = userSettings.client._source.googleDrivePathBackupId;
    //console.log('|||| googleDrivePathBackupId',googleDrivePathBackupId);
    const profileTypeId = userSettings.profile._source.profileTypeId;

    const accessdrive = userSettings.profile._source.accessdrive;
    //console.log('|||| profileTypeId',profileTypeId);
    return (
      <div className="app-main-menu d-none d-md-block">
        <ul className="navbar-nav navbar-nav-mega">
          {/* <li className="nav-item">
            <span className="nav-link">
              <IntlMessages id="appModule.sidebar.dashboard" />
            </span>
            <ul className="sub-menu">
              <li>
                <NavLink to="/app/dashboard/eCommerce">
                  <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="appModule.sidebar.dashboard.stats" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li> */}

          <li className="nav-item">
            <span className="nav-link">
              <IntlMessages id="appModule.sidebar.timelapses" />
            </span>
            <ul className="sub-menu">
              <li>
                <NavLink to="/app/timelapse/explorer">
                  <i className="zmdi zmdi-collection-folder-image zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="appModule.sidebar.images.explorer" />
                  </span>
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="/app/timelapse/compare">
                  <i className="zmdi zmdi-compare zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="appModule.sidebar.images.compare" />
                  </span>
                </NavLink>
              </li> */}
              <li>
                <NavLink to="/app/timelapse/compare">
                  <i className="zmdi zmdi-collection-image-o zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="appModule.sidebar.images.overlays" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <span className="nav-link">
              <IntlMessages id="appModule.sidebar.images" />
            </span>
            <ul className="sub-menu">
              {/* <li>
                <a href={"https://drive.google.com/drive/folders/1lAvR7c2ur6Kg2eiZ2Az8X-GJyHTDwAaz"} target="_blank">
                <i className="zmdi zmdi-collection-video zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="appModule.sidebar.timelapses.videos" />
                  </span>
                </a>
              </li> */}
            
              <li>
                <NavLink to="/app/timelapse/reports/videos">
                  <i className="zmdi zmdi-collection-video zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="appModule.sidebar.timelapses.videos" />
                  </span>
                </NavLink>
              </li>

              {/* <li>
                <a href={"https://drive.google.com/drive/folders/1BD90WTzVu8rZ6OCu2gV1gjRVVD7l8DWs"} target="_blank">
                <i className="zmdi zmdi-collection-image zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="appModule.sidebar.timelapses.images" />
                  </span>
                </a>
              </li> */}
              
              <li>
                <NavLink to="/app/timelapse/reports/images">
                  <i className="zmdi zmdi-collection-image zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="appModule.sidebar.timelapses.images" />
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/app/timelapse/reports/virtualtours">
                  <i className="zmdi zmdi-globe-alt zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="appModule.sidebar.timelapses.virtualtours" />
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/app/report/request">
                  <i className="zmdi zmdi-comment zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="appModule.sidebar.timelapses.request" />
                  </span>
                </NavLink>
              </li>
              
              {/* <li>
                <a href={"https://drive.google.com/drive/folders/"+googleDrivePathBackupId} target="_blank">
                <i className="zmdi zmdi-google-drive zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="appModule.sidebar.timelapses.backups" />
                  </span>
                </a>
              </li> */}
            </ul>
          </li>

          {profileTypeId <= 3 && 
            <li className="nav-item">
              <span className="nav-link">
                <IntlMessages id="appModule.sidebar.admin" />
              </span>

              <ul className="sub-menu">
                {profileTypeId <= 2 &&
                  <li>
                    <NavLink to="/app/admin/clients">
                      <i className="zmdi zmdi-city zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="appModule.sidebar.admin.client" />
                      </span>
                    </NavLink>
                  </li>
                }
                {profileTypeId <= 2 &&
                  <li>
                    <NavLink to="/app/admin/locals">
                      <i className="zmdi zmdi-pin-drop zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="appModule.sidebar.admin.locals" />
                      </span>
                    </NavLink>
                  </li>
                }
                {profileTypeId <= 2 &&
                  <li>
                    <NavLink to="/app/admin/cameras">
                      <i className="zmdi zmdi-camera zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="appModule.sidebar.admin.cameras" />
                      </span>
                    </NavLink>
                  </li>
                }
                {profileTypeId <= 2 &&
                  <li>
                    <NavLink to="/app/admin/virtualtours">
                      <i className="zmdi zmdi-globe-alt zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="appModule.sidebar.admin.virtualtours" />
                      </span>
                    </NavLink>
                  </li>
                }
                <li>
                  <NavLink to="/app/admin/profiles">
                    <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
                    <span className="nav-text">
                      <IntlMessages id="appModule.sidebar.admin.profiles" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/admin/users">
                    <i className="zmdi zmdi-account-box zmdi-hc-fw" />
                    <span className="nav-text">
                      <IntlMessages id="appModule.sidebar.admin.users" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/admin/permissions">
                    <i className="zmdi zmdi-shield-security zmdi-hc-fw" />
                    <span className="nav-text">
                      <IntlMessages id="appModule.sidebar.admin.permissions" />
                    </span>
                  </NavLink>
                </li>
                {profileTypeId <= 2 &&
                <li>
                  <NavLink to="/app/admin/requests">
                    <i className="zmdi zmdi-comment zmdi-hc-fw" />
                    <span className="nav-text">
                      <IntlMessages id="appModule.sidebar.admin.requests" />
                    </span>
                  </NavLink>
                </li>
                }
                
              </ul>
            </li>
          }

          {(profileTypeId <= 2 || accessdrive == true) && 
            <li className="nav-item">
              
              <span className="nav-link" >
                <a  className="" 
                  style={{
                      'color': 'white',
                      'display': 'contents'
                    }}
                  onClick={(evt) => {
                    this.openLink("https://drive.google.com/drive/folders/"+googleDrivePathBackupId, null, evt);
                  }}>
                  <IntlMessages id="appModule.sidebar.timelapses.drive" />
                </a> 
              </span>
                        
              {/* <ul className="sub-menu">
                
                <li>
                  <a href={"https://drive.google.com/drive/folders/"+googleDrivePathBackupId} target="_blank">
                    <i className="zmdi zmdi-google-drive zmdi-hc-fw" />
                    <span className="nav-text">
                      <IntlMessages id="appModule.sidebar.timelapses.backups" />
                    </span>
                  </a>
                </li>

              </ul> */}
            </li>
          }          


        </ul>
      </div>
    );
  }
}

export default withRouter(Menu);
