import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { userSignOut } from "actions/Auth";
import IntlMessages from "util/IntlMessages";
import { Link } from "react-router-dom";

class UserInfo extends React.Component {
  state = {
    anchorEl: null,
    open: false,
  };

  handleClick = (event) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    let userId = localStorage.getItem("user_id");
    let imgAvatar = null;
    let userName = "";
    let clientName = "";
    if (userId) {
      imgAvatar = JSON.parse(localStorage.getItem("userSettings_" + userId))
        .user._source.avatar;
      userName = JSON.parse(localStorage.getItem("userSettings_" + userId)).user
        ._source.name;
      clientName = JSON.parse(localStorage.getItem("userSettings_" + userId))
        .client._source.name;
    }

    return (
      <div className="user-profile d-flex flex-row align-items-center">
        <Avatar
          alt="..."
          src={
            imgAvatar !== undefined
              ? imgAvatar
              : require("assets/images/logo-mpd.png")
          }
          //src={require("assets/images/logo-mpd.png")}
          className="user-avatar "
        />
        <div className="user-detail">
          <h4 className="user-name" onClick={this.handleClick}>
            {userName !== undefined ? userName : "User"}{" "}
            <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
          </h4>
        </div>
        <Menu
          className="user-info"
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
          PaperProps={{
            style: {
              minWidth: 120,
              paddingTop: 0,
              marginTop: 30,
              paddingBottom: 0,
            },
          }}
        >
          {/* <MenuItem onClick={this.handleRequestClose}>
            <i className="zmdi zmdi-account zmdi-hc-fw mr-2"/>
            <IntlMessages id="appModule.infoUser.profile"/>
          </MenuItem>
          <MenuItem onClick={this.handleRequestClose}>
            <i className="zmdi zmdi-settings zmdi-hc-fw mr-2"/>
            <IntlMessages id="appModule.infoUser.setting"/>
          </MenuItem> */}

          <MenuItem>
            <Link
              to="/app/pass/change"
              style={{ color: "rgb(33 33 33)", textDecoration: "none" }}
            >
              <i className="zmdi zmdi-refresh-alt zmdi-hc-fw mr-2" />
              {/* <span className="nav-text"> */}
              <IntlMessages id="appModule.infoUser.change.pass" />
              {/* </span> */}
            </Link>
          </MenuItem>

          <MenuItem
            onClick={() => {
              this.handleRequestClose();
              this.props.userSignOut();
            }}
          >
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

            <IntlMessages id="appModule.infoUser.logout" />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale } = settings;
  return { locale };
};
export default connect(mapStateToProps, { userSignOut })(UserInfo);
