import React from 'react';
import {ConnectedRouter} from 'connected-react-router'
import {Provider} from 'react-redux';
import {Route, Switch} from 'react-router-dom';

import configureStore, {history} from './store';
import App from './containers/App';


//import {PersistGate} from 'redux-persist/integration/react';

//export const {store, storePersist, persistor} = configureStore();
export const store = configureStore();

const MainApp = () =>
  <Provider store={store}>
    {/* <PersistGate loading={null} persistor={persistor}> */}
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={App}/>
        </Switch>
      </ConnectedRouter>
    {/* </PersistGate> */}
  </Provider>;


export default MainApp;