import {INIT_URL, SIGNOUT_USER_SUCCESS, USER_DATA, USER_TOKEN_SET} from "../constants/ActionTypes";

const INIT_STATE = {
  token: localStorage.getItem('token'),
  initURL: '',
  authUser: (localStorage.getItem('user') != null? JSON.parse(localStorage.getItem('user')): ''),

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {


    case INIT_URL: {
      return {...state, initURL: action.payload};
    }

    case SIGNOUT_USER_SUCCESS: {
      const userInitURL = localStorage.getItem(
          "userInitURL_" + localStorage.getItem("user_id")
        );
        console.log('SIGNOUT_USER_SUCCESS userInitURL', userInitURL);
      return {
        ...state,
        token: null,
        authUser: null,
        initURL: (userInitURL !== undefined? userInitURL: '')
      }
    }

    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
      };
    }

    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
      };
    }

    default:
      return state;
  }
}
