import {applyMiddleware, compose, createStore} from 'redux';
import reducers from '../reducers/index';
import {createBrowserHistory} from 'history'
import {routerMiddleware} from 'connected-react-router';
import thunk from 'redux-thunk';

// novo
// import {persistStore, persistReducer} from 'redux-persist';
// import rootReducer from '../reducers';
// import storage from 'redux-persist/lib/storage'
// fim-novo

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);

const middlewares = [thunk, routeMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


// novo
// const persistConfig = {
//   key: 'timelapse',
//   storage
// };
// const persistedReducer = persistReducer(persistConfig, rootReducer)
// fim-novo

export default function configureStore(initialState) {
  // novo-parcial
  const store = createStore( reducers(history), initialState,
    composeEnhancers(applyMiddleware(...middlewares)));
  // const storePersist = createStore(persistedReducer);   
  // const persistor = persistStore(storePersist);
  // fim-novo-parcial

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers/index', () => {
      const nextRootReducer = require('../reducers/index');
      store.replaceReducer(nextRootReducer);
    });
  }

  // novo-parcial
  return store;
  // return {store, storePersist, persistor};
  // fim-novo-parcial
}
export {history};
