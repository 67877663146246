import grey from '@material-ui/core/colors/grey';
import deepOrange from '@material-ui/core/colors/deepOrange';

export default {
  palette: {
    primary: {
      light: grey[300],
      main: grey[500],
      dark: grey[700],
      contrastText: '#fff'
    },
    secondary: {
      light: deepOrange[300],
      main: deepOrange['A200'],
      dark: deepOrange[700],
      contrastText: '#fff'
    }
  },
  status: {
    danger: 'orange',
  },
  typography: {
    button: {
      fontWeight: 400,
      textAlign: 'capitalize'
    },
  },
};
