const languageData = [
  {
    languageId: 'portugues',
    locale: 'br',
    name: 'Português',
    icon: 'br'
  },
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  },
  {
    languageId: 'spanish',
    locale: 'es',
    name: 'Spanish',
    icon: 'es'
  }

];
export default languageData;
