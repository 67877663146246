import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const DataTable = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/explorer`} />
      <Route
        path={`${match.url}/explorer`}
        component={asyncComponent(() => import("./routes/Explorer/index"))}
      />
      <Route
        path={`${match.url}/download/:id/:name`}
        component={asyncComponent(() => import("./routes/Download/index"))}
      />
      <Route
        path={`${match.url}/reports/videos`}
        component={asyncComponent(() => import("./routes/VideosView/index"))}
      />
      <Route
        path={`${match.url}/reports/images`}
        component={asyncComponent(() => import("./routes/ImagesView/index"))}
      />
      <Route
        path={`${match.url}/reports/virtualtours`}
        component={asyncComponent(() => import("./routes/ToursView/index"))}
      />
      <Route
        path={`${match.url}/compare`}
        component={asyncComponent(() => import("./routes/Compare/index"))}
      />
      <Route
        component={asyncComponent(() =>
          import("app/routes/extraPages/routes/404")
        )}
      />
    </Switch>
  </div>
);

export default DataTable;
