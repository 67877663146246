import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import { userSignIn } from "actions/Auth";

class SignIn extends React.Component {
  constructor(props) {
    super(props);

  console.log('this.props',this.props)

    this.state = {
      email: "",
      password: ""
    };
    // this.state = {
    //   email: 'teste@teste.com',
    //   password: 'teste#123'
    // }
  };

  componentDidUpdate() {
    console.log('this.props.authUser',this.props.authUser );
    console.log('(this.props.authUser !== null)',(this.props.authUser !== null) );
    console.log('(this.props.authUser !== "")',(this.props.authUser !== "") );
    console.log('SignIn componentDidUpdate location.pathname',this.props.location.pathname);
    if (this.props.authUser !== null && this.props.authUser !== "") {
      // if(this.props.location.pathname === '/omprodutora'){
      //   this.props.history.push("/omprodutora");
      // }else{
        this.props.history.push("/");
      //}
    }
  };

  

  render() {
    const {match, location} = this.props;
    console.log('SignIn location.pathname',location.pathname, this.props.userSignIn);

    let logo = require("assets/images/logo.png");
    if(location.pathname === '/login'){
      logo = require("assets/images/logo.png");
    }else if(location.pathname === '/omprodutora'){
      logo = require("assets/images/logo-omprodutora.png");
    }

    const { email, password } = this.state;
    //const {showMessage, loader, alertMessage} = this.props;
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="Timelapse">
              <img style={{'width': '230px'}}
                src={logo}
                alt="Timelapse"
                title="Timelapse"
              />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>
                <IntlMessages id="appModule.login.title" />
              </h1>
            </div>

            <div className="app-login-form">
              <form>
                <fieldset>
                  {/* <label className="custom-checkbox float-left">
                    <span className="custom-control-description">
                      {<IntlMessages id="appModule.login.email" />}
                    </span>
                  </label>
                  <div className="form-group">
                    <input name="email" id="email" className="form-control form-control-lg"
                          placeholder="Email" type="email" value={email} 
                          onChange={event =>{
                            console.log("onChange email");
                            this.setState({ email: event.target.value });
                          }}
                          />
                  </div>
                  <label className="custom-checkbox float-left">
                    <span className="custom-control-description">
                      {<IntlMessages id="appModule.login.password" />}
                    </span>
                  </label>
                  <div className="form-group">
                    <input name="password" id="password" className="form-control form-control-lg"
                          placeholder="Password" type="password" value={password}
                          onChange={event => {
                            console.log("onChange password");
                            this.setState({ password: event.target.value });
                          }}
                          />
                  </div>                   */}
                  <TextField
                    label={<IntlMessages id="appModule.login.email" />}
                    fullWidth
                    onChange={event =>{
                        console.log("onChange email");
                        this.setState({ email: event.target.value })
                      }
                    }
                    //defaultValue={email}
                    value={email}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />
                  <TextField
                    type="password"
                    label={<IntlMessages id="appModule.login.password" />}
                    fullWidth
                    onChange={event => {
                      console.log("onChange password");
                      this.setState({ password: event.target.value });
                    }}
                    //defaultValue={password}
                    value={password}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button
                      onClick={() => {
                        setTimeout(() => {
                          this.props.userSignIn({ email, password });
                        }, 50);
                      }}
                      variant="contained"
                      color="primary"
                    >
                      <IntlMessages id="appModule.login.signIn" />
                    </Button>

                    {/* <Link to="/signup">
                      <IntlMessages id="appModule.login.signUp"/>
                    </Link> */}
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>

        <InfoView />
      </div>
    );

  };


}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};

export default connect(mapStateToProps, {
  userSignIn
})(SignIn);
