import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const DataTable = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/test`} />
      <Route
        path={`${match.url}/test`}
        component={asyncComponent(() => import("./routes/Test/index"))}
      />
      <Route
        path={`${match.url}/clients`}
        component={asyncComponent(() => import("./routes/Clients/index"))}
      />
      <Route
        path={`${match.url}/locals`}
        component={asyncComponent(() => import("./routes/Locals/index"))}
      />
      <Route
        path={`${match.url}/cameras`}
        component={asyncComponent(() => import("./routes/Cameras/index"))}
      />
      <Route
        path={`${match.url}/virtualtours`}
        component={asyncComponent(() => import("./routes/VirtualTours/index"))}
      />
      <Route
        path={`${match.url}/profiles`}
        component={asyncComponent(() => import("./routes/Profiles/index"))}
      />
      <Route
        path={`${match.url}/permissions`}
        component={asyncComponent(() => import("./routes/Permissions/index"))}
      />
      <Route
        path={`${match.url}/users`}
        component={asyncComponent(() => import("./routes/Users/index"))}
      />
      <Route
        path={`${match.url}/requests`}
        component={asyncComponent(() => import("./routes/Requests/index"))}
      />
      <Route
        component={asyncComponent(() =>
          import("app/routes/extraPages/routes/404")
        )}
      />
    </Switch>
  </div>
);

export default DataTable;
